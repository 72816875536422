/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  orderBy,
} from "firebase/firestore";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { getDownloadURL, ref } from "firebase/storage";
import Link from "next/link.js";

import styles from "./Header.module.css";

import { texts } from "../../../texts.js";
import { db, firebaseStorage } from "@/_firebase/firebaseConfig.js";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import GhostBox from "@/components/shared/GhostBox/GhostBox";
import SadFace from "@/components/shared/GhostBox/SadFace";
import { useDebounce } from "@/helpers/useDebounce";
import MagnifyingGlassImage from "@/components/shared/GhostBox/MagnifyingGlassImage";
import SearchIcon from "./SearchIcon";
import archetypesJsonData from "../../../assets/traitInfo/archetypes.json";
import elementsJsonData from "../../../assets/traitInfo/elements.json";
import patternsJsonData from "../../../assets/traitInfo/patterns.json";
import { TraitElement } from "@/models/sharedModels";
import Element from "@/components/traits/element/Element/Element";
import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import Pattern from "@/components/traits/pattern/Pattern/Pattern";
import { getDefaultProfilePhoto } from "@/context/utils";

export default function SearchBar() {
  const [search, setSearch] = useState<string>("");
  const [profileResults, setProfileResults] = useState<PublicProfile[] | []>(
    []
  );
  const [imagURls, setImageURls] = useState<any[] | []>([]);
  const [traitResults, setTraitResults] = useState<TraitElement[] | []>([]);
  const [searchCount, setSearchCount] = useState<number>(0);

  const debouncedValue = useDebounce<string>(search, 500);

  useEffect(() => {
    const searchForProfiles = async () => {
      setSearchCount((current) => current + 1);
      const profileRef = collection(db, "publicProfiles");
      const q = query(
        profileRef,
        where("searchTerms", "array-contains", search.toLowerCase()),
        orderBy("hasPhoto", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      let promises = querySnapshot.docs.map((doc) => {
        setProfileResults((current: any) => [...current, doc.data()]);
        return getDownloadURL(
          ref(
            firebaseStorage,
            `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${
              doc.data().ownerUUID
            }/cropped.jpg`
          )
        )
          .then((url) => {
            return url;
          })
          .catch(async (err) => {
            return await getDefaultProfilePhoto(doc.data().ownerUUID).then(
              (value) => {
                console.log(value);
                return value;
              }
            );
          });
      });
      setImageURls(await Promise.all(promises));
    };
    const searchForTraits = () => {
      elementsJsonData.forEach((el) => {
        if (el.isDiscoverable === true) {
          if (
            el.name.toLowerCase().includes(search.toLowerCase()) ||
            el.alias?.toLowerCase().includes(search.toLowerCase())
          ) {
            setTraitResults((current) => [
              ...current,
              el as unknown as TraitElement,
            ]);
          }
        }
      });
      archetypesJsonData.forEach((el) => {
        if (
          el.isDiscoverable === true &&
          el.archetypeGroupSlug !== "spirit" &&
          el.archetypeGroupSlug !== "values"
        ) {
          if (
            el.name.toLowerCase().includes(search.toLowerCase()) ||
            el.acronym?.toLowerCase().includes(search.toLowerCase())
          ) {
            setTraitResults((current) => [
              ...current,
              el as unknown as TraitElement,
            ]);
          }
        }
      });
      patternsJsonData.forEach((el) => {
        if (el.isDiscoverable === true) {
          if (el.name.toLowerCase().includes(search.toLowerCase())) {
            setTraitResults((current) => [
              ...current,
              el as unknown as TraitElement,
            ]);
          }
        }
      });
    };

    if (searchCount > 1000) {
      return;
    } else {
      if (search.length > 3) {
        setProfileResults([]);
        setImageURls([]);
        searchForProfiles();
      } else {
        setProfileResults([]);
        setImageURls([]);
      }
      setTraitResults([]);
      searchForTraits();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const colorMap = ProfileColorMap;

  const mapped = profileResults.map((person, index) => {
    return (
      <Link
        href={`/${person.userName}`}
        onClick={(e) => {
          setSearch("");
          setProfileResults([]);
          setImageURls([]);
        }}
        className={styles.personDiv}
        key={index}
      >
        <div
          className={styles.imageContainer}
          style={
            person.primaryNature?.nature
              ? {
                  border: `3px solid ${colorMap[person.primaryNature?.nature]}`,
                }
              : {
                  border: `3px solid #55504F`,
                }
          }
        >
          <img
            width={50}
            height={50}
            alt=""
            src={imagURls[index] ?? "/default-image.png"}
            className={styles.smallImage}
          />
        </div>
        <div className={styles.bioAndUsername}>
          <h5>{person.name}</h5>
          <p>{`@${person.userName}`}</p>
        </div>
      </Link>
    );
  });

  const traitsMapped = traitResults.map((el, index) => {
    if (el.traitContent.traitType === "element") {
      return (
        <Link
          onClick={() => {
            setSearch("");
          }}
          href={`/traits/elements/${el.slug}`}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Element slug={el.slug} />
        </Link>
      );
    } else if (el.traitContent.traitType === "archetype") {
      return (
        <Link
          onClick={() => {
            setSearch("");
          }}
          href={`/traits/archetypes/${el.slug}`}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Archetype
            // @ts-ignore
            archetype={el}
          />
        </Link>
      );
    } else {
      return (
        <Link
          onClick={() => {
            setSearch("");
          }}
          href={`/traits/patterns/${el.slug}`}
          key={el.slug}
          className="elBodyFiveMArgin"
        >
          <Pattern patternSlug={el.slug} />
        </Link>
      );
    }
  });

  return (
    <div id="searchBarParent" className={styles.searchMain}>
      <div style={{ width: "0" }}>
        <SearchIcon />
      </div>
      <input
        placeholder={texts.searchUsersAndTraits}
        className={styles.searchBar}
        type="text"
        onChange={(e) => {
          setSearch(e.target.value);
          // handleChange(e.target.value);
        }}
        value={search}
      />
      {search.length > 0 && (
        <div className={styles.searchResultDivDesktop}>
          <h3>Traits</h3>
          {traitResults.length > 0 && (
            <div className={styles.traitResultDivDesktop}>{traitsMapped}</div>
          )}
          {traitResults.length === 0 && (
            <GhostBox
              onlyMessage
              message="No traits found"
              image={<SadFace />}
            />
          )}
          <h3 style={{ marginTop: "20px" }}>People</h3>
          {profileResults.length > 0 && <div>{mapped}</div>}
          {profileResults.length === 0 && search.length > 3 && (
            <GhostBox
              onlyMessage
              message="No users found"
              image={<SadFace />}
            />
          )}
          {search.length < 4 && search.length > 0 && (
            <GhostBox
              image={<MagnifyingGlassImage />}
              onlyMessage={true}
              message="You must type at least 4 characters"
            />
          )}
        </div>
      )}
    </div>
  );
}
